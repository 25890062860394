const selfSet = {
  'pages.settings.organization.self-settings.phone': '手机号',
  'pages.settings.organization.self-settings.updatePhone': '修改手机',
  'pages.settings.organization.self-settings.set': '个人设置',
  'pages.settings.organization.self-settings.old': '原密码',
  'pages.settings.organization.self-settings.updatePassword': '修改密码',
  'pages.settings.organization.self-settings.new': '新密码',
  'pages.settings.organization.self-settings.bindPhone': '绑定手机',
  'pages.settings.organization.self-settings.newPhone': '新手机号',
  'pages.settings.organization.self-settings.sureNew': '确认新密码',
  'pages.settings.organization.self-settings.updateEmail': '修改邮箱',
  'pages.settings.organization.self-settings.emailNum': '邮箱账号',
  'pages.settings.organization.self-settings.bindEmail': '绑定邮箱',
  'pages.settings.organization.self-settings.bindWeChat': '绑定微信',
  'pages.settings.organization.self-settings.avatar': '头像',
  'pages.settings.organization.self-settings.startTime': '注册时间',
  'pages.settings.organization.self-settings.basicMsg': '基本信息',
  'pages.settings.organization.self-settings.safeMsg': '安全信息',
  'pages.settings.organization.self-settings.': '注册时间',
  'pages.settings.organization.self-settings.loginPassword': '登录密码',
  'pages.settings.organization.self-settings.third': '第三方账号',
  'pages.settings.organization.self-settings.account': '账号',
  'pages.settings.organization.self-settings.bound': '已绑定',
  'pages.settings.organization.self-settings.unbind': '解绑',
  'pages.settings.organization.self-settings.bind': '绑定',
  'pages.settings.organization.self-settings.selectImg': '选择图片',
  'pages.settings.organization.self-settings.sendMsg':
    '邮件发送成功，请登录 {field} 邮箱，点击邮件内的链接进行地址确认',
  'pages.settings.organization.self-settings.unbindMsg':
    '你正在进行微信 ({field}) 解绑操作，解绑后微信扫码登录将会不可用',
  'pages.settings.organization.self-settings.verificationCode.error': '验证码错误',
  'pages.settings.organization.self-settings.set.account.password':
    '设置账号密码(账号设置后无法更改请谨慎操作)',
  'pages.settings.organization.self-settings.set.account': '登录账号',
  'pages.settings.organization.self-settings.input.password': '输入密码',
  'pages.settings.organization.self-settings.confirm.password': '确定密码',
  'pages.settings.organization.self-settings.password': '密码',
  'pages.settings.organization.self-settings.organization.remark.name': '企业备注名',
  'pages.settings.organization.self-settings.card': '个人名片',
  'pages.settings.organization.self-settings.updateCard': '修改个人名片',
  'pages.settings.organization.self-settings.addCard': '添加个人名片',
  'pages.settings.organization.self-settings.noSame': '两次密码不一致',
}

export default selfSet
