const staff = {
  'pages.platform.user.department.title': '部门',
  'pages.platform.user.department.field.department': '部门',
  'pages.platform.user.department.field.departmentName': '部门名称',
  'pages.platform.user.department.field.subDepartment': '子部门',
  'pages.platform.user.department.field.supDepartment': '上级部门',
  'pages.platform.user.staff.createModal.title': '新建员工',
  'pages.platform.user.staff.passwordModal.title': '修改密码',
  'pages.platform.user.staff.field.code': '工号',
  'pages.platform.user.staff.field.nameOrUsername': '姓名/登录名',
  'pages.platform.user.staff.field.role': '角色',
  'pages.platform.user.staff.field.roleDescription': '角色描述',
  'pages.platform.user.staff.field.roles': '拥有角色',
  'pages.platform.user.staff.field.department': '部门',
  'pages.platform.user.staff.field.job': '职位',
  'pages.platform.user.staff.field.kingdeeCode': 'ERP编码',
  'pages.platform.user.staff.field.givePermission': '权限下放',
  'pages.platform.user.staff.field.password': '登录密码',
  'pages.platform.user.staff.action.resetPassword': '修改密码',
  'pages.platform.user.staff.action.invite': '邀请员工',
  'pages.platform.user.staff.action.create': '新建员工',
  'pages.platform.user.staffDrawer.title.basic': '基本信息',
  'pages.platform.user.staffDrawer.title.department': '部门',
  'pages.platform.user.staffDrawer.title.role': '角色',
  'pages.platform.user.staffDrawer.hint.selectDepartment': '有未选择的部门',
  'pages.platform.user.staffDrawer.hint.inputPostName': '有未填写的职位',
  'pages.platform.user.staffInvite.title': '邀请员工加入',
  'pages.platform.user.staffInvite.action.clickToSave': '点击保存图片',
  'pages.platform.user.staffInvite.scanIt': '扫一扫',
  'pages.platform.user.staffInvite.sendQrCode': '将二维码图片发送给目标用户',
  'pages.platform.user.staffInvite.joinOrganization': '打开微信一扫一扫即可加入企业',
  'pages.platform.user.staffInvite.invitationLink': '邀请链接',
  'pages.platform.user.staffInvite.sendLink': '将链接发送给目标用户',
  'pages.platform.user.staffInvite.clickJoin': '点击即可加入企业',
  'pages.platform.user.staffInvite.invitationQrCodeName': '火山湖-邀请员工-二维码',
  'pages.platform.user.staff.status.frozen': '冻结',
  'pages.platform.user.staff.status.frozen.wait.join': '待加入',
  'pages.platform.user.staffDrawer.title.repetition.department': '部门重复,请修改',
  'pages.platform.user.staffDrawer.title.repetition.role': '角色重复,请修改',
  'pages.platform.user.staffDrawer.status.waitApproved': '待审核',
  'pages.platform.user.staffDrawer.status.approveReject': '审核拒绝',
  'pages.platform.user.staffDrawer.status.not.register': '该员工还未注册',
  'pages.platform.user.staff.delete.staff': '删除员工',
  'pages.platform.user.staff.delete.hint.one': '请确认您要删除这个员工。',
  'pages.platform.user.staff.delete.hint.two':
    '这个员工将会从企业内移除。删除后员工需重新申请加入企业。',
  'pages.platform.user.staff.delete.hint.three':
    '如果您不想让这个员工继续登录您的企业，通常是冻结，而不是删除。',
  'pages.platform.user.staff.delete.department.hint.error': '这个部门包含子部门，无法删除',
  'pages.platform.user.staff.delete.department': '删除部门',
  'pages.platform.user.staff.delete.department.hint.one': '请确认您要删除这个部门。',
  'pages.platform.user.staff.delete.department.hint.two':
    '这个部门将会被彻底删除，删除后相关员工自动解除与这个部门的关联。',
  'pages.platform.user.staff.delete.role': '删除角色',
  'pages.platform.user.staff.delete.role.hint.one': '请确认您要删除这个角色。',
  'pages.platform.user.staff.delete.role.hint.two':
    '这个角色将会被彻底删除，删除后相关员工自动解除与这个角色的关联。',
  'pages.platform.user.staff.delete.department.hint':
    '这个部门将会被彻底删除，员工无法再加入这个部门。',
  'pages.platform.user.staff.invite.link.hint':
    '点击链接加入我的企业“{organizationName}”，一起开启全新协同制造体验吧。',

  'pages.platform.user.staffDrawer.title.basic.seat.authorization': '席位授权',
}

export default staff
